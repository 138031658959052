import Vue from 'vue'
Vue.config.productionTip = false

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)

import store from '@/store/index'
import App from '@/App.vue'
import tools from '@/utils/tools'

// 全局 WebSocket 配置
import ws from '@/utils/global-ws'
import voice from '@/utils/voice'
Vue.prototype.$ws = ws;
Vue.prototype.$voice = voice;

//文字是否可以被选中标识
Vue.prototype.WES_TEXT_SELECT_FLAG = !!localStorage.getItem('WES-TEXT-SELECT-FLAG');

import router from '@/router.js'

import ElementUI from 'element-ui'
import '@/styles/element-variables.scss'

import '@/styles/index.scss' // global css
import i18n from './utils/i18n'

Vue.use(ElementUI);
Vue.use(tools);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="component-page-header">
    <!-- {{ header }} -->
    <el-link icon="el-icon-s-home" :underline="false" href="#/index">
      {{ (header ? 'WES' : '') + (username ? "（" + username + ")" : null) }}
    </el-link>
  </div>
</template>

<style lang="scss" scoped>
.component-page-header /deep/ {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:70px;
  line-height:40px;
  padding:0 10px;
  background:linear-gradient(to bottom,rgba(54, 108, 179, 0.4), #fff);

  .el-link {
    color:#444;
    font-size:1.6rem;
    font-weight:bold;
  }
}
</style>

<script>
export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

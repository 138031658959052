const state = {
  uid: 0,
  username: null,
  token: null
}

const mutations = {
  setUser(state, user) {
    state.uid = user.uid;
    state.username = user.username;
    state.token = user.token;
  }
}

const getters = {
  getUsername(state) {
    return state.username;
  },
  getUid(state) {
    return state.uid;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
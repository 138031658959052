import {Notification, MessageBox} from 'element-ui'
import i18n from './i18n'

const ws = {
  vue: null,
  client: null,
  url: null,
  /**
   * -1. 未连接/连接失败；0. 初始化中；1. 连接成功；
   */
  status: -1,
  retryCount: 0,
  initClient: initClient,
  send: send,
  sayHello: sayHello,
  printLabels: printLabels,
  getVersion: getVersion,
  getPrinterList: getPrinterList,
  /**
   * 最后收到的服务消息内容
   */
  lastMessage: {},
  /**
   * 返回的标签数据（有条形码的标签数据）
   */
  labelBarcodeList: []
}

/**
 * 初始化
 */
function initClient() {
  if (ws.client !== null && ws.client.readyState === WebSocket.CONNECTING) {
    ws.client.onclose = () => {}
    ws.client.onerror = () => {}
    ws.close();
  }

  ws.url = localStorage.getItem('ws_url') || 'ws://127.0.0.1:9999';
  let wsClient = new WebSocket(ws.url);
  wsClient.onopen = websocketOpen;
  wsClient.onclose = websocketClose;
  wsClient.onerror = websocketError;
  wsClient.onmessage = websocketMessage;
  ws.client = wsClient;
  updateStatus(0)
}

/**
 * ws 连接成功回调
 */
function websocketOpen() {
  updateStatus(1)
  ws.retryCount = 0;
  Notification({
    type: 'success',
    title: 'WesLinker',
    message: 'WesLinker connected.'
  });
}

/**
 * ws 连接关闭回调
 */
function websocketClose() {
  updateStatus(-1)

  // Notification({
  //   type: 'warning',
  //   title: 'WesLinker',
  //   message: 'WesLinker connect closed.'
  // });

  // 连接关闭了也要维护某些状态
  if (ws.vue && ws.vue.$store.state.detector) {
    ws.vue.$store.state.detector.isDeviceActive = false
    ws.vue.$store.state.detector.waitTrigger = false
  }
}

/**
 * ws 错误回调
 * @param event
 */
function websocketError(event) {
  console.error('WebSocket error observed:', event);
  ws.retryCount++;
  if (ws.retryCount > 3) {
    return
  }
  setTimeout(initClient, 1000 * ws.retryCount);

  // Notification({
  //   type: 'error',
  //   title: 'WesLinker',
  //   message: 'WesLinker connect error.'
  // });
}

/**
 * 接受到消息的回调
 *
 * @param msg
 */
function websocketMessage(msg) {
  console.log('WebSocket received message:', msg.data);
  const action = JSON.parse(msg.data)
  const data = action.Data
  ws.lastMessage = action;

  //返回错误消息，直接弹窗挡掉
  if (data.Code && data.Code !== 0) {
    MessageBox.alert(data.Msg, "WesLinker Error", {
      type: 'error',
      showClose: false
    })
    ws.vue.$store.state.labelCheck.waitForResponse = false;
    return
  }

  switch (action.ActionType) {
    case 0: {
      // 错误提示
      Notification({
        type: data.Code === 0 ? 'success' : 'error',
        title: 'WesLinker',
        message: data.Msg
      });
      //如果有返回的标签数据（有条形码的标签数据），存储起来，用于进行标签检测
      if (data.Data && data.Data.LabelBarcodeList) {
        ws.labelBarcodeList = data.Data.LabelBarcodeList;
      }
      ws.vue.$store.state.labelCheck.waitForResponse = false;
    }
      break;
    case 3: {
      // 红外探测器被触发
      console.log('红外感应触发')
      if (ws.vue && ws.vue.$store.state.detector) {
        if (data.Status === 0) {
          // 设备插入
          console.log('红外感应设备插入')
          ws.vue.$store.state.detector.isDeviceActive = true
        } else if (data.Status === 1) {
          // 设备拔出
          console.log('红外感应设备拔出')
          ws.vue.$store.state.detector.isDeviceActive = false
        } else if (data.Status === 2) {
          // 物体触发
          console.log('红外感应物体触发')
          ws.vue.$store.state.detector.isDeviceActive = true
          ws.vue.$store.state.detector.waitTrigger = false
        }
      }
    }
      break;
    case 5: {
      // 获取版本号
      ws.vue.$store.state.version = data;
    }
      break;
    case 7: {
      // 获取本地打印机
      ws.vue.$store.state.printers = data;
    }
      break;
    case 8: {
      // QC 异常，锁定操作界面，需要管理员解锁
      ws.vue.$store.state.labelCheck.labelQcNg = true;
    }
      break;
  }
}

/**
 * 发送消息
 * @param data
 */
function send(data) {
  if (ws.client && ws.status === 1) {
    ws.client.send(JSON.stringify(data));
  } else {
    MessageBox.alert(i18n.t('base.wes_linker_unlink_msg'), i18n.t('base.wes_linker_unlink_title'), {
      type: 'error',
      showClose: false
    });
  }
}

/**
 * 测试
 *
 * @param what
 */
function sayHello(what) {
  send({
    ActionType: 9999,
    Data: {
      Say: 'Hello. ' + (what || '')
    }
  });

  // console.log(what)
  // let labels = [
  //   {
  //     "template": "http://sz-dev.spreadigital.vip:9000/spread/resource/label/template/label_template/312c7033-ec72-443c-88bc-f43a10a0d035.btw",
  //     "number": 1,
  //     "PartNO": "pn0001",
  //     "Qty": 2000,
  //     "CartonSn": "sn0001",
  //     "EndCustomerName": "ALLSOR(TW)",
  //     "OrderNo": "REC21050600004"
  //   }
  // ]
  // let data = {
  //   PkgId: "SXT20220317C001",
  //   BoxNo: "Box1"
  // }
  // send({
  //   ActionType: 4,
  //   Data: data
  // });
}

/**
 * 打印标签
 *
 * @param labels 标签内容
 */
function printLabels(labels) {
  //将标签大小绑定的打印机放到标签当中，发送到wesLinker
  let printerConfig = JSON.parse(localStorage.getItem('printer_config'))
  //将用户登录时的设备好绑定的警报器IP放到标签当中，发送到wesLinker
  let user = JSON.parse(localStorage.getItem('user'))
  if (labels && labels.length && null !== printerConfig && printerConfig.length) {
    labels.forEach(item => {
      let conf = printerConfig.filter(config => config.labelSize.toUpperCase() === item.labelSize.toUpperCase())[0]
      if (conf) {
        item.printerName = conf.printerName
      }
      item.alarmIpAddress = user && user.alarmIpAddress ? user.alarmIpAddress : ''
    })
  }
  send({
    ActionType: 1,
    Data: labels
  });
}

/**
 * 获取版本
 */
function getVersion() {
  send({
    ActionType: 5
  });
}

/**
 * 获取本地打印机列表
 */
function getPrinterList() {
  send({
    ActionType: 7
  });
}

/**
 * 更新连接状态
 *
 * @param status -1. 未连接/连接失败；0. 初始化中；1. 连接成功；
 */
function updateStatus(status){
  ws.status = status
  ws.vue.$store.state.flow.isWsLinked = status > 0
  console.log("is ws linked ?" + (status > 0))
}

export default ws

<template>
  <el-container style="height: 100%">
    <!-- page-header start -->
    <PageHeader :header="header" :username="username"></PageHeader>
    <!-- page-heaer end -->
    <div class="flex-1" style="margin-top:40px">
      <router-view/>
    </div>
    <el-footer style="height:40px; box-shadow:0 -1px 5px rgba(0,0,0,0.1) inset">
      <!-- WebSocket 连接指示器 -->
      <div class="flex flex-ac flex-jb" style="height:100%">
        <span @click="reConnect()">
          {{ $t('config.local_service_status') }}<span v-if="ws.status < 0"><icon class="el-icon-remove text-danger"/> Disconnected.</span><span
            v-if="ws.status === 0"><icon
            class="el-icon-s-promotion text-warning"/> Connecting... Retry times: {{ ws.retryCount }}</span><span
            v-if="ws.status > 0"><icon class="el-icon-success text-success"/> Connected.</span> [{{ ws.url }}]
        </span>

        <span class="end-customer-show" v-if="endCustomer">{{ endCustomer.name }} [{{ endCustomer.code }}]</span>

        <span>
          <el-button size="mini" icon="el-icon-setting" circle @click="systemConfigDrawer = true"></el-button>
        </span>
      </div>
    </el-footer>

    <el-drawer
        :title="$t('config.title')"
        :visible.sync="systemConfigDrawer"
        direction="rtl"
        size="500px"
        :destroy-on-close="true">
      <GlobalConfig></GlobalConfig>
    </el-drawer>

    <el-dialog :visible.sync="waitTrigger" :append-to-body="true" :close-on-click-modal="false"
               :close-on-press-escape="false" :show-close="false" :center="true" width="30%">
      <div class="text-center" style="font-size: x-large">
        {{ $t('config.take_label') }}
        <p/>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="labelQcNg" :append-to-body="true" :close-on-click-modal="false"
               :close-on-press-escape="false" :show-close="false" :center="true" width="30%">
      <div class="text-center mb10" style="font-size: x-large">
        {{ $t('config.supervisor_unlocks') }}
      </div>
      <el-form :model="managerAccount" :rules="managerAccountRules" ref="form" label-position="top" label-width="100px"
               :status-icon="true">
        <el-form-item :label="$t('login.username')" prop="username">
          <el-input v-model="managerAccount.username" autocomplete="on" @blur="getSalt"></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.password')" prop="password">
          <el-input v-model="managerAccount.password" show-password></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.captcha')" prop="verCode">
          <el-col :span="14">
            <el-input
                v-model="managerAccount.verCode"
                @keyup.enter.native="checkManagerConfirm"/>
          </el-col>
          <el-col :span="10" style="padding-left: 1em">
            <el-image :src="managerAccount.captcha" @click="getSalt">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"/>
              </div>
            </el-image>
          </el-col>
        </el-form-item>
        <div class="text-center">
          <el-button type="primary" @click="checkManagerConfirm">{{ $t('base.confirm') }}</el-button>
        </div>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import GlobalConfig from "@/components/global-config/index"
import PageHeader from "@/components/page-header/index"
import {mapActions, mapState} from "vuex";
import sha256 from "js-sha256";
import * as authApi from "@/apis/auth";

export default {
  name: "App",
  data() {
    return {
      header: '',
      username: this.$localStorage.get('nickname'),
      ws: this.$ws,
      voice: this.$voice,
      systemConfigDrawer: false,
      managerAccount: {
        username: null,
        password: null,
        verCode: null,
        verKey: '',
        salt: '',
        captcha: '',
      },
      managerAccountRules: {
        username: [
          {required: true, message: this.$i18n.t('login.username_cannot_be_empty'), trigger: 'blur'}
        ],
        password: [
          {required: true, message: this.$i18n.t('login.password_cannot_be_empty'), trigger: 'blur'}
        ],
        verCode: [
          {required: true, message: this.$i18n.t('login.auth_code_cannot_be_empty'), trigger: 'blur'},
          {min: 4, max: 4, message: this.$i18n.t('login.auth_code_digit_error'), trigger: 'blur'}
        ]
      },
    };
  },
  components: {GlobalConfig, PageHeader},
  created() {
    this.initWebSocket();
    this.initVoice()
  },
  watch: {
    '$route.path': function (route) {
      if (route.startsWith('/receiving')) {
        this.header = 'Synergy-WES ' + this.$t('index.inbound');
      } else if (route.startsWith('/delivery')) {
        this.header = 'Synergy-WES ' + this.$t('index.outbound');
      } else {
        this.header = 'Synergy-WES';
      }
    }
  },
  methods: {
    initWebSocket() {
      this.$ws.vue = this
      this.reConnect()
    },
    initVoice() {
      this.$voice.init()
    },
    reConnect() {
      if (this.$ws.status === 1) return
      this.$ws.initClient();
    },
    /**
     * 获取 Salt 和 验证码图片
     */
    getSalt() {
      if (!this.managerAccount.username) {
        return;
      }
      authApi.getSalt(this.managerAccount.username)
          .then(
              rsp => {
                this.managerAccount.salt = rsp.salt;
                this.managerAccount.captcha = rsp.base64;
                this.managerAccount.verKey = rsp.key;
              }
          );
    },
    checkManagerConfirm() {
      this.$refs.form.validate()
          .then(() => {
            let timestamp = Date.now();
            let encryptedPwd = sha256(sha256(sha256(this.managerAccount.password).toUpperCase() + this.managerAccount.salt).toUpperCase() + timestamp).toUpperCase();
            let form = {
              timestamp: timestamp,
              password: encryptedPwd,
              verCode: this.managerAccount.verCode,
              verKey: this.managerAccount.verKey
            };

            authApi.login(form, this.managerAccount.username)
                .then(
                    (rsp) => {
                      console.log(rsp);
                      // 查找是否有解锁 NG 权限
                      if (rsp.actionPermissionList.includes('wes_skip_label_qc')) {
                        this.skipQc();
                      } else {
                        this.$alert(this.$i18n.t('base.check_action_permission'), {
                          type: 'error',
                          showClose: false,
                          showCancelButton: true,
                          showConfirmButton: false,
                        });
                      }
                    },
                    error => {
                      this.$alert(error, {
                        type: 'error',
                        showClose: false,
                        showCancelButton: true,
                        showConfirmButton: false,
                      });
                    }
                );
          })
          .catch((errors) => {
            console.log(errors);
            this.$message.error(errors);
            return false;
          })
          .finally(() => {
            this.managerAccount.username = null;
            this.managerAccount.password = null;
            this.managerAccount.verCode = null;
            this.managerAccount.verKey = null;
            this.managerAccount.salt = null;
            this.managerAccount.captcha = null;
          });
    },
    ...mapActions('labelCheck', ['skipQc']),
  },
  computed: {
    ...mapState('flow', {
      endCustomer: 'endCustomer'
    }),
    ...mapState('detector', ['waitTrigger']),
    ...mapState('labelCheck', ['labelQcNg'])
  },
};
</script>

<style>
html {
  height: 100%;
  margin: auto;
}

body {
  height: 100%;
  margin: 0;
}

</style>

const state = {
  history: []
}

const mutations = {
  ADD_HISTHORY(state, item) {
    if(state.history[0] === item) return

    state.history.unshift(item);
    if (state.history.length > 50) {
      state.history = state.history.slice(0, 50);
    }
  }
}

const actions = {
  addHistory({ commit }, item) {
    commit('ADD_HISTHORY', item);
  }
}

const getters = {
  getHistory(state) {
    return state.history;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
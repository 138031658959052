import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// Router Mapping
const routes = [
  // Index
  {path: '/index', component: () => import('@/views/index/index')},
  {path: '/login', component: () => import('@/views/index/login')},

  // Receiving
  {path: '/receiving', component: () => import('@/views/receiving/index')},

  // Delivery
  {path: '/delivery', component: () => import('@/views/delivery/index')},
]

// Init Router
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let token = Vue.localStorage.get("token");
  if (to.path === '/login' && token) {
    // 已经登录的在退出前不在进入登录界面，进入 index 界面
    next('/index')
  } else if (to.path !== '/login' && !token) {
    // 未登录的只能进入登录界面
    next('/login')
  } else if (to.path === '/') {
    // 已经登录的，访问根目录默认跳首页
    next('/index')
  } else {
    next()
  }
})

export default router
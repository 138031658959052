const state = {
  /**
   * 设备是否插入
   */
  isDeviceActive: false,
  /**
   * 是否显示等待遮罩
   */
  waitTrigger: false
}

const mutations = {
  CHANGE_DEVICE_STATE(state, payload) {
    state.isDeviceActive = payload.isActive
  },
  TRIGGER_MASK_LAYER(state, payload) {
    if (!state.isDeviceActive) return
    state.waitTrigger = payload.isWait
  }
}

const actions = {
  /**
   * 设备插入状态
   * @param isActive 设备是否插入
   */
  changeDeviceState({commit}, isActive) {
    commit('CHANGE_DEVICE_STATE', {isActive})
  },
  /**
   * 改变等待红外感应遮罩
   * @param isWait 是否显示等待遮罩
   */
  triggerMaskLayer({commit}, isWait) {
    commit('TRIGGER_MASK_LAYER', {isWait})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
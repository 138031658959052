const state = {
    /**
     * 是否在等待响应
     */
    waitForResponse: false,
    labelQcNg: false
}

const mutations = {
    CHANGE_LABEL_CHECK_WAIT_STATE(state, payload) {
        state.waitForResponse = payload.isWait
    },
    CHANGE_LABEL_QC_NG_STATE(state, payload) {
        state.labelQcNg = payload.labelQcNg
    }
}

const actions = {
    /**
     * 改变是否等待标签打印机打印的状态
     * @param isActive 设备是否插入
     */
    changeLabelCheckWaitState({commit}, isWait) {
        commit('CHANGE_LABEL_CHECK_WAIT_STATE', {isWait})
    },
    /**
     * 改变是否是否标签检测异常
     * @param labelQcNg 是否标签检测异常
     */
    skipQc({commit}) {
        commit('CHANGE_LABEL_QC_NG_STATE', { labelQc: false})
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
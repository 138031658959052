import request from './request';

/**
 * 流程查找 <br/>
 * 本接口会查找当前输入是 PKGID 或者各单的单号，然后判断当前数据关联的 END CUSTOMER, SUPPLIER, CONSIGNEE，以及当前进行
 * 中的流程后返回相关数据
 */
export function checkFlow(data) {
  return request.get(`/warehouse/manager/action/basic/checkFlow`, {params: data});
}

/**
 * 报警器拉响
 * @param ipAddress 报警器地址
 */
export function ringTheAlarm(ipAddress) {
  return request.get(`/warehouse/manager/action/basic/ringTheAlarm?ipAddress=${ipAddress}`);
}

/**
 * 获取wesLinker版本信息
 */
export function getWesLinkerVersion() {
  return request.get(`/basic/info/wesLinkerVersion`);
}

/**
 * 更换默认仓库
 */
export function setDefaultHub(hubId) {
  return request({
    url: `/user/hub/${hubId}/setDefault`,
    method: 'post'
  })
}

/**
 * 获取基础信息列表
 * @return {json} 列表數據
 */
export function getBasicInfoList(data) {
  return request({
    url: `/basic/info/basicInfo`,
    method: 'get',
    params: data
  })
}

/**
 * 通过订单号获取关联的 PN 供展示
 * @return {json} 列表數據
 */
export function listPnByOrder(orderId) {
  return request.get(`/warehouse/manager/action/basic/listPnByOrder/${orderId}`);
}


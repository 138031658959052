const voice = {
  init: init,
  playOk: playOk,
  playFail: playFail
}

function init(){
  voice.okAudio = new Audio('res/ScanOkSound.wav')
  voice.okAudio.preload = "auto"
  voice.failAudio = new Audio('res/ScanFailSound.wav')
  voice.failAudio.preload = "auto"
}

/**
 * 播放成功的喜悦
 */
async function playOk(){
  if(voice.okAudio){
    await voice.okAudio.play()
  }
}

/**
 * 播放失败的悲鸣
 */
async function playFail(){
  if(voice.failAudio){
    await voice.failAudio.play()
  }
}

export default voice
<template>
  <el-container>
    <el-main>
      <el-form label-position="left" label-width="110px">
        <div v-if="showUserInfo">
          <el-divider content-position="left"> {{ $t('config.hi')}}{{ this.$localStorage.get('nickname') }}</el-divider>

          <el-form-item :label="$t('login.username')">
            <span class="user-info">{{ this.$localStorage.get('username') }}</span>
          </el-form-item>
          <el-form-item :label="$t('login.device')">
            <span class="user-info">{{ this.$localStorage.get('device') }}</span>
          </el-form-item>
          <el-form-item v-if="isShowToken" label="Token">
            <span class="user-info">{{ this.$localStorage.get('token') }}</span>
          </el-form-item>
          <el-form-item :label="$t('login.hub')">
            <el-select v-model="hubId" @change="changeHub">
              <el-option v-for="item in hubList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('login.printer_config')">
            <el-button @click="showPrinterConfig = true">{{ $t('config.printer_config_btn') }}</el-button>
          </el-form-item>
          <el-form-item label="WesLinker" v-if="wesLinkerVersion">
              <span class="user-info">{{ needToUpdateWesLinker ? 'New' : 'Now'}} Version：{{ wesLinkerVersion.length === undefined ? wesLinkerVersion.version : wesLinkerVersion[0].version }}</span>
              <el-tag v-if="!needToUpdateWesLinker" effect="plain" style="margin-left: 20px; font-size: 12px">{{ $t('config.already_the_latest_version') }}</el-tag>
              <el-button v-if="needToUpdateWesLinker" style="margin-left: 20px; font-size: 12px" type="primary" @click="wesLinkerDownloadDialog = true">{{ $t('config.download_the_latest_version') }}</el-button>
          </el-form-item>
          <el-form-item :label="$t('PDA')">
            <el-button @click="showPdaDownload = true">{{ $t('base.download') }}</el-button>
          </el-form-item>
        </div>

        <el-divider content-position="left">{{ $t('config.basic_config') }}</el-divider>
        <el-form-item :label="$t('config.language')">
          <el-select v-model="language" @change="updateLanguage">
            <el-option v-for="item in supportedLanguages" :key="'lang_' + item.value"
                       :label="item.name" :value="item.value"/>
          </el-select>
        </el-form-item>

        <el-divider content-position="left"> Service</el-divider>
        <el-form-item label="URL">
          <el-input v-model="apiBaseUrl" @blur="updateApiBaseUrl"></el-input>
        </el-form-item>

        <el-divider content-position="left"> Local WebSocket</el-divider>
        <el-form-item label="URL">
          <el-col :span="18">
            <el-input v-model="wsUrl" @blur="updateWsUrl"></el-input>
          </el-col>
          <el-col :span="4" style="padding-left: 5px;">
            <el-button @click="testWsUrl">Hello</el-button>
          </el-col>
        </el-form-item>


        <el-divider content-position="left"> {{ $t('config.label_check') }}</el-divider>
        <div class="mb10 config-font">
          {{ $t('config.first_times_check_1') }}<el-input class="ml10 mr10 number-input" v-model="labelCheckBeforeEachOrder" @blur="updateLabelCheckBeforeEachOrder"></el-input>{{ $t('config.first_times_check_2') }}
        </div>
        <div class="mb10 config-font">
          {{ $t('config.between_check_label_1') }}<el-input class="ml10 mr10 number-input" v-model="labelCheckByEachLabel" @blur="updateLabelCheckByEachLabel"></el-input>{{ $t('config.between_check_label_2') }}
        </div>
        <div class="mb10 config-font">
          {{ $t('config.current_count_of_errors') }}<el-input class="ml10 mr10 number-input" v-model="labelCheckCountOfErrorsNow" @blur="updateLabelCheckCountOfErrorsNow"></el-input>, {{ $t('config.error_count_1') }}<el-input class="ml10 mr10 number-input" v-model="labelCheckCountOfErrors" @blur="updateLabelCheckCountOfErrors"></el-input>{{ $t('config.error_count_2') }}
        </div>
      </el-form>

      <el-button type="danger" style="width: 100%" v-show="showUserInfo" @click="logout">{{ $t('config.logout') }}</el-button>
    </el-main>

    <div>
      <!-- 打印机配置 start -->
      <el-dialog
          :title="$t('config.printer_config')"
          :visible.sync="showPrinterConfig"
          :modal="true"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :center="true"
          width="800px">

        <!-- list-wrap start -->
        <div class="global-list-wrap flex-1 flex flex-dc mt10">
          <div class="list-header text-center">
            <el-row>
              <el-col :span="2">No.</el-col>
              <el-col :span="6">{{ $t('config.label_size') }}</el-col>
              <el-col :span="16">{{ $t('config.bound_printer') }}</el-col>
            </el-row>
          </div>

          <div class="list-content flex-1 text-center">
            <el-row v-for="(item, index) in printerConfigList" :key="index">
              <el-col :span="2" style="height: 48px;padding-top: 16px">{{ index + 1 }}</el-col>
              <el-col :span="6" style="height: 48px;padding-top: 16px">{{ item.labelSize }}</el-col>
              <el-col :span="16" style="height: 48px" :style="!editFlag || editActiveId !== index ? 'padding-top: 16px' : ''">
                <el-link v-if="!editFlag || editActiveId !== index" @click="editFlag = true; editActiveId = index">
                  {{ item.printerName ? item.printerName + $t('config.edit') : $t('config.setting') }}
                </el-link>
                <el-select v-if="editFlag && editActiveId === index" size="small"
                           v-model="currentPrinter" @change="bindPrinter(index)">
                  <el-option v-for="item in printerList"
                             :key="item"
                             :label="item"
                             :value="item"/>
                </el-select>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- list-wrap end -->

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showPrinterConfig = false">{{ $t('config.save') }}</el-button>
        </span>
      </el-dialog>
      <!-- 打印机配置 end -->

      <!-- WesLinker Download start -->
      <el-dialog
          title="WesLinker Download"
          :visible.sync="wesLinkerDownloadDialog"
          :modal="true"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :center="true"
          width="400px">

        <div class="flex flex-js">
          <el-link v-for="wesLinker in wesLinkerVersion" :key="wesLinker.id" type="primary" target="_blank" :href="wesLinker.downloadUrl" download="WesLinker">
            {{ wesLinker.downloadPlatform }}
          </el-link>
        </div>
      </el-dialog>
      <!-- WesLinker Download end -->

      <!-- PDA Download start -->
      <el-dialog
          title="PDA Download"
          :visible.sync="showPdaDownload"
          :modal="true"
          :modal-append-to-body="true"
          :append-to-body="true"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :center="true"
          width="400px">

        <div class="flex flex-js">
          <div><img src="/img/PDA_DOWNLOAD.png"></div>
        </div>
      </el-dialog>
      <!-- PDA Download end -->
    </div>
  </el-container>
</template>

<style lang="scss" scoped>

.user-info {
  border-radius: 4px
}

.el-form-item {
  margin-bottom: 12px;
}

.number-input{
  width: 54px;
}

.config-font {
  font-size: 1.4rem;
}

</style>

<script>

import ws from "@/utils/global-ws";
import * as basicActionApi from '@/apis/basic-action';

export default {
  data() {
    return {
      supportedLanguages: [
        {
          name: 'English',
          value: 'en'
        },
        {
          name: '简体中文',
          value: 'zh-CN'
        },
        {
          name: '繁体中文',
          value: 'zh-TW'
        }
      ],
      language: this.$localStorage.get('lang', navigator.language || navigator.userLanguage),
      apiBaseUrl: this.$localStorage.get('api_base_url', 'http://sz-dev.spreadigital.vip:8001/v1'),
      wsUrl: this.$localStorage.get('ws_url', 'ws://127.0.0.1:9999'),
      user: JSON.parse(this.$localStorage.get('user')),
      labelCheckBeforeEachOrder: this.$localStorage.get('label_check_before_each_order', '5'),
      labelCheckByEachLabel: this.$localStorage.get('label_check_by_each_label', '30'),
      labelCheckCountOfErrorsNow: this.$localStorage.get('label_check_count_of_errors_now', '0'),
      labelCheckCountOfErrors: this.$localStorage.get('label_check_count_of_errors', '5'),
      wesLinkerVersion: JSON.parse(this.$localStorage.get('wes_linker_version')),
      needToUpdateWesLinker: true,
      wesLinkerDownloadDialog: false,
      hubId: null,
      hubList: null,
      //绑定打印机
      printerList: [],
      printerConfigList: JSON.parse(this.$localStorage.get('printer_config')),
      showPrinterConfig: false,
      showPdaDownload: false,
      currentPrinter: '',
      editFlag: false,
      editActiveId: null
    }
  },
  created() {
    this.hubId = this.user.currentHubId;
    this.hubList = this.user.hubList;
    //如果报错请确保 wesLinkerVersion 有值！
    this.checkWesLinkerVersion();
    //获取本地打印机列表
    this.getPrinterList();
    //获取标签打印机配置
    this.getLabelPrinterConfig();
  },
  computed: {
    showUserInfo: function () {
      return this.$localStorage.get('token')
    }
  },
  methods: {
    /**
     * 切换语言
     */
    updateLanguage() {
      this.$i18n.locale = this.language
      this.$localStorage.set('lang', this.language)
    },
    /**
     * 切换仓库
     */
    changeHub(hubId) {
      basicActionApi.setDefaultHub(hubId).then(() => {
        this.hubId = hubId;
        this.user.currentHubId = this.hubId;
        this.$localStorage.set('user', JSON.stringify(this.user));
        this.$localStorage.set('currentHubId', this.hubId);
      }).catch(() => {});
    },
    /**
     * 更新请求地址
     */
    updateApiBaseUrl() {
      if (this.$data.apiBaseUrl) {
        this.$localStorage.set('api_base_url', this.$data.apiBaseUrl)
      } else {
        this.$localStorage.remove('api_base_url')
      }
    },
    /**
     * 更新 WebSockets 地址
     */
    updateWsUrl() {
      if (this.$data.wsUrl) {
        this.$localStorage.set('ws_url', this.$data.wsUrl)
      } else {
        this.$localStorage.remove('ws_url')
      }
      this.$ws.initClient()
      this.$ws.vue = this
    },
    testWsUrl() {
      if (!this.$ws.client) {
        this.$ws.initClient()
        this.$ws.vue = this
      }
      this.$ws.sayHello()
    },
    logout() {
      this.$localStorage.remove('username')
      this.$localStorage.remove('user')
      this.$localStorage.remove('token')

      window.location.reload(true)
    },
    /**
     * 更新每單前幾次出標
     * 并且归零当前每单的出标次数
     */
    updateLabelCheckBeforeEachOrder() {
      if (this.$data.labelCheckBeforeEachOrder) {
        this.$localStorage.set('label_check_before_each_order', this.$data.labelCheckBeforeEachOrder)
        this.$localStorage.set('label_check_before_each_order_now', 0)
      } else {
        this.$localStorage.remove('label_check_before_each_order')
        this.$localStorage.remove('label_check_before_each_order_now')
      }
    },
    /**
     * 更新每間隔幾張標簽
     * 并且归零当前的间隔标签数
     */
    updateLabelCheckByEachLabel() {
      if (this.$data.labelCheckByEachLabel) {
        this.$localStorage.set('label_check_by_each_label', this.$data.labelCheckByEachLabel)
        this.$localStorage.set('label_check_by_each_label_now', 0)
      } else {
        this.$localStorage.remove('label_check_by_each_label')
        this.$localStorage.remove('label_check_by_each_label_now')
      }
    },
    /**
     * 更新當前已錯誤次數
     */
    updateLabelCheckCountOfErrorsNow() {
      if (this.$data.labelCheckCountOfErrorsNow) {
        this.$localStorage.set('label_check_count_of_errors_now', this.$data.labelCheckCountOfErrorsNow)
      } else {
        this.$localStorage.remove('label_check_count_of_errors_now')
      }
    },
    /**
     * 更新拉响警报的错误到达次数
     */
    updateLabelCheckCountOfErrors() {
      if (this.$data.labelCheckCountOfErrors) {
        this.$localStorage.set('label_check_count_of_errors', this.$data.labelCheckCountOfErrors)
      } else {
        this.$localStorage.remove('label_check_count_of_errors')
      }
    },
    /**
     * 检测wesLinker版本是否需要更新
     */
    checkWesLinkerVersion() {
      //获取小蜜蜂的版本
      if (this.$ws.status > 0) {
        this.$ws.getVersion();
        setTimeout(() => {
          //小蜜蜂的版本
          let nowVersion = ws.vue.$store.state.version;
          //服务器wesLinker的版本
          let newVersion = this.wesLinkerVersion ? this.wesLinkerVersion.length === undefined ? '0' : this.wesLinkerVersion[0].version : '0';

          if (!nowVersion || parseFloat(newVersion) > parseFloat(nowVersion.split(' ')[0])) {
            this.needToUpdateWesLinker = true;
          } else {
            this.needToUpdateWesLinker = false;
          }
        }, 1000);
      } else {
        this.needToUpdateWesLinker = true;
      }
    },
    /**
     * 获取本地打印机列表
     */
    getPrinterList() {
      if (this.$ws.status > 0) {
        this.$ws.getPrinterList();
        setTimeout(() => {
          this.printerList = ws.vue.$store.state.printers;
        }, 1000);
      }
    },
    /**
     * 获取标签打印机配置
     */
    getLabelPrinterConfig() {
      //如果缓存里面没有数据，则从服务器中获取
      if (null === this.printerConfigList || !this.printerConfigList) {
        //初始化数据，从服务器中获取标签大小
        this.getLabelSizeList();
      }
    },

    /**
     * 获取标签大小列表
     */
    getLabelSizeList() {
      this.loading = true;

      const data = {
        type: 21
      }

      basicActionApi.getBasicInfoList(data).then(res => {
        this.loading = false;
        this.printerConfigList = []
        res.forEach(item => {
          let temp = {
            labelSize: item.name,
            printerName: ''
          }
          this.printerConfigList.push(temp)
        })
        //放入缓存
        this.$localStorage.set('printer_config', JSON.stringify(this.printerConfigList))
      }).catch(() => {
        this.loading = false;
      });
    },

    /**
     * 标签绑定打印机
     */
    bindPrinter(index) {
      this.printerConfigList[index].printerName = this.currentPrinter
      //刷新缓存
      this.$localStorage.set('printer_config', JSON.stringify(this.printerConfigList))

      //清空
      this.currentPrinter = ''
      this.editFlag = false
    }
  }
}
</script>

import Vue from 'vue'
import axios from 'axios';
import qs from 'qs';
import {MessageBox, Message} from 'element-ui';
import VueLocalStorage from 'vue-localstorage'
import {getLanguage} from "@/utils/i18n";
import i18n from '@/utils/i18n'


Vue.use(VueLocalStorage)

// 全局配置
axios.defaults.baseURL = Vue.localStorage.get('api_base_url', process.env.VUE_APP_BASE_API);
axios.defaults.timeout = 30000;

// 请求前的配置
axios.interceptors.request.use(
  config => {
    // 如果已经登录需要注入 token
    let token = Vue.localStorage.get('token');
    if (token) {
      config.headers.common['S-Token'] = token;
    }

    config.data = qs.stringify(config.data, {arrayFormat: 'repeat'})

    // 因为 axios 默认是使用 json body 来提交数据的，这里我们需要将其转换为 form 表单，数组也改用重复字段表示
    if (config.method === 'post' || config.method === 'put') {
      config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    config.headers['Accept-Language'] = getLanguage()

    return config;
  },
  error => {
    console.log(error)
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.data.code === 4) {
        MessageBox.confirm(i18n.t('base.login_expired'), i18n.t('base.tip'), {
            confirmButtonText: i18n.t('login.relogin'),
            showCancelButton: true,
            closeOnClickModal: false,
            closeOnPressEscape: true,
            showClose: false,
            type: 'warning'
        }).then(() => {
            Vue.localStorage.remove('username')
            Vue.localStorage.remove('user')
            Vue.localStorage.remove('token')
            window.location.reload(true)
        });
        return Promise.reject(new Error(i18n.t('base.login_expired')));
    } else if (response.data.code !== 0) {
      Message({
        message: response.data.msg,
        type: 'error'
      });
      return Promise.reject(new Error(response.data.msg));
    }

    return response.data.data;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default axios;

const state = {
  endCustomer: null,
  supplier: null,
  consignee: null,
  orderNo: null,
  orderId: null,
  flowMap: null,
  enableInBound: false,
  enableOutBound: false,
  isWsLinked: false,
}

const mutations = {
  SET_END_CUSTOMER(state, customer) {
    state.endCustomer = customer
  },
  SET_SUPPLIER(state, customer) {
    state.supplier = customer
  },
  SET_CONSIGNEE(state, customer) {
    state.consignee = customer
  },
  SET_ORDER_NO(state, orderNo) {
    state.orderNo = orderNo;
  },
  SET_ORDER_ID(state, orderId) {
    state.orderId = orderId;
  },
  SET_FLOWS(state, flows) {
    state.enableInBound = false;
    state.enableOutBound = false;

    let flowMap = new Map();
    flows.forEach(flow => {
      switch (flow.mainFlow.mainFlowType) {
        case 1:
          // 允许收货作业
          state.enableInBound = true;
          break;
        case 2:
          // 允许出货作业
          state.enableOutBound = true;
          break;
      }

      flowMap.set(flow.id, flow)
    })

    state.flowMap = flowMap
  },
  SET_IS_WS_LINKED(state, isLinked){
    state.wsLinked = isLinked
  }
}

const actions = {
  setEndCustomer({commit}, customer) {
    commit('SET_END_CUSTOMER', customer)
  },
  setSupplier({commit}, customer) {
    commit('SET_SUPPLIER', customer)
  },
  setConsignee({commit}, customer) {
    commit('SET_CONSIGNEE', customer)
  },
  setOrderNo({commit}, orderNo) {
    commit('SET_ORDER_NO', orderNo)
  },
  setOrderId({commit}, orderId) {
    commit('SET_ORDER_ID', orderId)
  },
  setFlows({commit}, flows) {
    commit('SET_FLOWS', flows)
  },
  updateFlow({commit}, flow) {
    commit('SET_END_CUSTOMER', flow.endCustomer)
    commit('SET_SUPPLIER', flow.supplier)
    commit('SET_CONSIGNEE', flow.consignee)
    commit('SET_FLOWS', flow.flows)
    commit('SET_ORDER_NO', flow.orderNo)
    commit('SET_ORDER_ID', flow.orderId)
  },
  setIsWsLinked({commit}, isLinked) {
    commit('SET_IS_WS_LINKED', isLinked)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}